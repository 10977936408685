import locationIcon from '@resources/svg/pin-gray.svg';
import { truncate } from 'lodash';

export default function useProjectCardPopup() {
    const getProjectPopup = async ($wire, projectId, dataExtras) => {
        const popupData = await $wire.getPopupData(projectId);
        const project = popupData.project;

        return `<div x-data="{
            coverImage: '${project.cover_image ?? dataExtras.defaultImage}',
            logo: '${project.project_logo ?? dataExtras.defaultImage}',
            title: '${truncate(project.name, 25) ?? 'project'}',
            address: '${project.address ?? ''}',
            minPrice: '${project.price_from ?? ''}',
            maxPrice: '${project.price_to ?? ''}',
            minSurfaceArea: '${project.min_surface_area ?? ''}',
            maxSurfaceArea: '${project.max_surface_area ?? ''}',
            surfaceAreaUnit: '${project.surface_area_unit ?? ''}',
        }">
            <div class="project-popup__container">
                <div class="project-popup__image__header">
                    <a href="${project.local_full_path}" target="_blank">
                        <div class="absolute top-0 left-0 h-30 w-full bg-gradient-to-b from-black via-transparent to-transparent opacity-70"></div>
                        <img class="project-popup__splash-image" alt="splash" x-bind:src="coverImage">
                        <template x-if="logo">
                            <div class="project-popup__logo">
                                <img alt="logo" x-bind:src="logo">
                            </div>
                        </template>
                    </a>
                </div>
                <div class="project-popup__content">
                    <span class="project-popup__content-title" x-text="title"></span>

                    <template x-if="address">
                        <span class="project-popup__content-location">
                            <img class="-mt-1" width="16px" height="16px" src="${locationIcon}" alt="location">
                            <span x-text="address"></span>
                        </span>
                    </template>

                    <template x-if="minPrice || minSurfaceArea">
                        <div class="project-popup__content-badges">
                            <template x-if="minPrice">
                                <div class="project-popup__content-badge">
                                    <span x-text="minPrice"></span>
                                    <template x-if="maxPrice && (minPrice != maxPrice)">
                                        <span>
                                            <span>-</span>
                                            <span x-text="maxPrice"></span>
                                        </span>
                                    </template>
                                </div>
                            </template>
                            <template x-if="minSurfaceArea">
                                <div class="project-popup__content-badge">
                                    <span x-text="minSurfaceArea"></span>
                                    <template x-if="maxSurfaceArea && (minSurfaceArea != maxSurfaceArea)">
                                        <span>
                                            <span>-</span>
                                            <span x-text="maxSurfaceArea"></span>
                                        </span>
                                    </template>

                                    <template x-if="surfaceAreaUnit">
                                        <span x-text="surfaceAreaUnit"></span>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </template>

                    ${popupData.phoneButtonContent || ''}
                </div>
            </div>
        </div>`;
    };

    return {
        getProjectPopup,
    };
}
