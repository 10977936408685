import humanNumber from 'human-number';
import roomIcon from '@resources/svg/room.svg';
import floorsIcon from '@resources/svg/floors.svg';
import dropdownIcon from '@resources/svg/downArrow.svg';
import dimensionIcon from '@resources/svg/dimension.svg';

let dataExtras = [];

const PROJECT_LOGO = 6;
const PROJECT_LISTINGS = 8;
const PROJECT_LISTING_ID = 0;
const PROJECT_LISTING_PRICE = 2;
const PROJECT_LISTING_PATH = 3;
const PROJECT_LISTING_COVER_IMAGE = 4;
const PROJECT_LISTING_NUMBER_OF_ROOMS = 5;
const PROJECT_LISTING_USABLE_SURFACE = 6;
const PROJECT_LISTING_FLOOR_NUMBER = 7;
const PROJECT_LISTING_NUMBER_OF_FLOORS = 8;

export default function useProjectListingsPopup() {
    const renderPrice = price => {
        price = humanNumber(price * 1000, n => (Number.isInteger(n) ? n : Number.parseFloat(n).toFixed(1)));
        return price;
    };

    const isInteger = value => {
        return Number.isInteger(parseInt(value));
    };

    const popupTransition = (saveProjectMapRaoLogs, projectId) => {
        let ul = document.querySelector('.project-card__listing__list-wrapper');
        let dropdownIcon = document.querySelector('.project-card__listing__detail--icon__header');
        document.querySelector('.project-card__listing__wrapper__header').addEventListener('click', function () {
            ul.classList.toggle('expand');
            dropdownIcon.classList.toggle('rotate');
            saveProjectMapRaoLogs(projectId);
        });
    };

    const popupContainerTemplate = (projectLogo, listings, minPrice, maxPrice, minRooms, maxRooms) => {
        let popupContainer = document.createElement('div');
        popupContainer.classList.add('project-card__listings__container');
        popupContainer.innerHTML += `<div class="project-card__listing__wrapper__header">
                <div class="project-card__listing">
                    <div class="project-card__listing__img-wrapper__header">
                        <img class="project-card__listing__img" src="${projectLogo}">
                    </div>
                    <div class="project-card__listing__details-wrapper__header">
                        <span class="project-card__listing__price--header"></span>
                        <span class="project-card__listing__rooms"></span>
                    </div>
                    <div class="project-card__listing__total">
                        <span>${listings.length}</span>
                        <span>${listings.length === 1 ? dataExtras.ads.singular : dataExtras.ads.plural}</span>
                        <img class="project-card__listing__detail--icon__header" src="${dropdownIcon}" alt="icon">
                    </div>
                </div>
            </div>
            <ul class="project-card__listing__list-wrapper"></ul>
            \n`;

        if (minPrice === maxPrice) {
            popupContainer.querySelector('.project-card__listing__price--header').innerText =
                `${dataExtras.currencySymbol} ${minPrice}`;
        } else {
            popupContainer.querySelector('.project-card__listing__price--header').innerText =
                `${dataExtras.currencySymbol} ${minPrice} - ${dataExtras.currencySymbol} ${maxPrice}`;
        }

        if (minRooms > 0) {
            if (minRooms === maxRooms) {
                popupContainer.querySelector('.project-card__listing__rooms').innerText =
                    `${minRooms} ${dataExtras.roomLabel}`;
            } else {
                popupContainer.querySelector('.project-card__listing__rooms').innerText =
                    `${minRooms} - ${maxRooms} ${dataExtras.roomLabel}`;
            }
        }

        return popupContainer;
    };

    const listingCardTemplate = () => {
        let card = document.createElement('li');
        card.classList.add('project-card__listing__wrapper');
        card.innerHTML += `<a target="_blank" href="#" class="project-card__listing">
            <div class="project-card__listing__img-wrapper">
                <img class="project-card__listing__img" alt="logo">
            </div>
            <div class="project-card__listing__details-wrapper">
                <span class="project-card__listing__price"></span>
                <div class="project-card__listing__details"></div>
            </div>
        </a>`;

        return card;
    };

    const populateListingCard = (listings, popupContainer, popupListingCard, projectLogo) => {
        for (let listing of listings) {
            const projectCard = popupListingCard.cloneNode(true);
            projectCard.setAttribute('data-listing-id', listing[PROJECT_LISTING_ID]);

            let price = Number.isInteger(listing[PROJECT_LISTING_PRICE])
                ? listing[PROJECT_LISTING_PRICE]
                : Number.parseFloat(listing[PROJECT_LISTING_PRICE]).toFixed(3);
            price = price.toLocaleString(dataExtras.appLocale);

            if (price) {
                projectCard.querySelector('.project-card__listing__price').innerText =
                    `${price} ${dataExtras.currencySymbol}+${dataExtras.vat}`;
            }

            if (listing[PROJECT_LISTING_PATH]) {
                projectCard.querySelector('.project-card__listing').href = listing[PROJECT_LISTING_PATH];
            }

            if (listing[PROJECT_LISTING_COVER_IMAGE]) {
                projectCard.querySelector('.project-card__listing__img').src =
                    `${listing[PROJECT_LISTING_COVER_IMAGE]}`;
            } else {
                projectCard.querySelector('.project-card__listing__img').src = `${projectLogo}`;
            }

            if (isInteger(listing[PROJECT_LISTING_NUMBER_OF_ROOMS])) {
                projectCard.querySelector('.project-card__listing__details').innerHTML +=
                    `<span class="project-card__listing__detail-room">
                    <img src="${roomIcon}" alt="${dataExtras.roomLabel}">
                    <div class="project-card__listing__details--text-wrapper">
                        <span class="project-card__listing__details--text bold">
                            ${parseInt(listing[PROJECT_LISTING_NUMBER_OF_ROOMS])}
                        </span>
                        <span class="project-card__listing__details--text">${dataExtras.roomLabel}</span>
                    </div>
                </span>`;
            }

            if (isInteger(listing[PROJECT_LISTING_USABLE_SURFACE])) {
                projectCard.querySelector('.project-card__listing__details').innerHTML +=
                    `<span class="project-card__listing__detail-area">
                    <img src="${dimensionIcon}" alt="dimension">
                    <div class="project-card__listing__details--text-wrapper">
                        <span class="project-card__listing__details--text bold">
                            ${humanNumber(parseInt(listing[PROJECT_LISTING_USABLE_SURFACE]), n =>
                                Number.isInteger(n) ? n : Number.parseFloat(n).toFixed(0),
                            )}
                        </span>
                        <span class="project-card__listing__details--text">${dataExtras.squareMetresLabel}</span>
                    </div>
                </span>`;
            }

            if (listing[PROJECT_LISTING_FLOOR_NUMBER]) {
                let floorNumberContent = listing[PROJECT_LISTING_FLOOR_NUMBER];

                if (isInteger(listing[PROJECT_LISTING_NUMBER_OF_FLOORS])) {
                    floorNumberContent = `${floorNumberContent}/${parseInt(listing[PROJECT_LISTING_NUMBER_OF_FLOORS])}`;
                }

                if (floorNumberContent) {
                    projectCard.querySelector('.project-card__listing__details').innerHTML +=
                        `<span class="project-card__listing__detail-floor">
                        <img src="${floorsIcon}" alt="${dataExtras.floorLabel}">
                        <div class="project-card__listing__details--text-wrapper">
                            <span class="project-card__listing__details--text">${floorNumberContent}</span>
                        </div>
                    </span>`;
                }
            }

            popupContainer.querySelector('.project-card__listing__list-wrapper').innerHTML += projectCard.outerHTML;
        }

        return popupContainer.outerHTML;
    };

    const computeMinMaxPrices = listings => {
        const prices = listings.map(listing => parseFloat(listing[PROJECT_LISTING_PRICE]));
        const minPrice = renderPrice(Math.min(...prices));
        const maxPrice = renderPrice(Math.max(...prices));

        return {
            minPrice,
            maxPrice,
        };
    };

    const computeMinMaxRooms = listings => {
        const rooms = listings.map(listing =>
            parseInt(listing[PROJECT_LISTING_NUMBER_OF_ROOMS]) ? parseInt(listing[PROJECT_LISTING_NUMBER_OF_ROOMS]) : 0,
        );
        const minRooms = `${Math.min(...rooms)}`;
        const maxRooms = `${Math.max(...rooms)}`;

        return {
            minRooms,
            maxRooms,
        };
    };

    const getProjectPopup = (pin, mapDataExtras) => {
        let projectLogo = pin[PROJECT_LOGO] ?? mapDataExtras.defaultImage;

        dataExtras = mapDataExtras;

        let { minPrice, maxPrice } = computeMinMaxPrices(pin[PROJECT_LISTINGS]);

        let { minRooms, maxRooms } = computeMinMaxRooms(pin[PROJECT_LISTINGS]);

        let popupContainer = popupContainerTemplate(
            projectLogo,
            pin[PROJECT_LISTINGS],
            minPrice,
            maxPrice,
            minRooms,
            maxRooms,
        );
        let popupListingCard = listingCardTemplate();

        return populateListingCard(pin[PROJECT_LISTINGS], popupContainer, popupListingCard, projectLogo);
    };

    return {
        popupTransition,
        getProjectPopup,
    };
}
