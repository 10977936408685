import ListingSearchResultsMapComponent from '../../components/ListingSearchResultsMap.js';
import ProjectSearchResultsMapComponent from '../../components/ProjectSearchResultsMap.js';
import ProjectMapComponent from '../../components/ProjectMap.js';
import LocationMapComponent from '../../components/LocationMap.js';

const Alpine = window.Alpine;

Alpine.data('ListingSearchResultsMapComponent', ListingSearchResultsMapComponent);
Alpine.data('ProjectSearchResultsMapComponent', ProjectSearchResultsMapComponent);
Alpine.data('ProjectMapComponent', ProjectMapComponent);
Alpine.data('LocationMapComponent', LocationMapComponent);
