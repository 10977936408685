import L from 'leaflet';
import map from 'lodash.map';
import chunk from 'lodash.chunk';
import reduce from 'lodash.reduce';
import set from 'lodash.set';
import 'leaflet/dist/leaflet.css';
import '@resources/css/leaflet.rrose.css';
import '@resources/css/theme/imo/leaflet-map.css';
import '@resources/js/app/composables/leaflet/leaflet.rrose-src.js';
import '@resources/js/app/composables/leaflet/leaflet.patches.js';
import useUrl from '@resources/js/app/composables/useUrl.js';
import useCustomMarker from '@resources/js/app/composables/useCustomMarker.js';
import useProjectCardPopup from '@resources/js/app/composables/useProjectCardPopup.js';
import isequal from 'lodash.isequal';

const { hasUrlParam, setUrlParam, getUrlParam, unsetUrlParam } = useUrl();
const { getProjectMarker } = useCustomMarker();
const { getProjectPopup } = useProjectCardPopup();

const PROJECT_ID = 0;

let self = null;

export default $wire => ({
    map: null,
    cancelTokenSource: null,
    fetchingPoints: false,
    fetchingPage: false,
    processingPoints: false,
    loadingProgress: 2,
    maxBounds: null,
    mapDataExtras: [],
    activeLayerGroup: null,

    index: [],
    pages: [],

    viewportMarkers: [],
    hoverMarker: null,
    sort: null,

    async init() {
        self = this;

        if (!$wire.showMap || window.innerWidth < 1200) {
            unsetUrlParam('map-zoom', true);
            unsetUrlParam('map-latitude', true);
            unsetUrlParam('map-longitude', true);

            $wire.showMap = false;

            return true;
        }

        const maxBounds = [
            [$wire.bounds[1], $wire.bounds[0]],
            [$wire.bounds[3], $wire.bounds[2]],
        ];

        document.getElementById('map').innerHTML = '';
        self.map = L.map('map', {
            zoom: $wire.zoom,
            center: $wire.center,
            maxZoom: $wire.maxZoom,
            minZoom: $wire.minZoom,
            maxBounds: maxBounds,
            zoomControl: false,
            zoomAnimation: true,
            markerZoomAnimation: true,
            fadeAnimation: false,
            editable: true,
            doubleClickZoom: false,
            boxZoom: false,
        })
            .on('moveend', async e => {
                const newCenter = self.map.getCenter();
                const newMapZoom = parseInt(self.map.getZoom());

                setUrlParam('map-latitude', newCenter.lat, true);
                setUrlParam('map-longitude', newCenter.lng, true);
                setUrlParam('map-zoom', newMapZoom, true);
            })
            .on('zoomend', function (e) {
                self.setZoomScale(e.target._zoom);
            })
            .on('zoomend moveend', async function () {
                await self.fetchPage(getUrlParam('page') || 1);
            })
            .on('popupopen', e => {
                const m = e.popup.marker;
                self.hoverMarker = m;
                L.DomUtil.addClass(m._icon, 'hover');
                m.popupOpen = true;
            })
            .on('popupclose', e => {
                const m = e.popup.marker;
                self.hoverMarker = null;
                document.querySelectorAll('.hover').forEach(m => {
                    m.classList.remove('hover');
                });
                m.popupOpen = false;
            });

        self.mapDataExtras = Object.assign({}, $wire.mapDataExtras);

        new L.tileLayer($wire.mapUrl, {
            opacity: 0.7,
            zIndex: 30,
            subdomains: $wire.subdomains,
            detectRetina: false,
        }).addTo(self.map);

        L.control
            .zoom({
                position: 'bottomright',
            })
            .addTo(self.map);

        self.setZoomScale($wire.zoom);
        self.activeLayerGroup = L.layerGroup().addTo(self.map);

        self.$watch('fetchingPoints', value => {
            if (value === true) {
                self.progressInterval = setInterval(() => {
                    if (self.loadingProgress < 50) {
                        self.loadingProgress += 5;
                    }
                }, 200);
            }
            if (value === false) {
                clearInterval(self.progressInterval);
            }
        });

        await self.fetchPins();
    },

    async fetchPins() {
        self.fetchingPoints = true;
        self.processingPoints = true;

        // Cancel the previous requests if exists
        if (self.cancelTokenSource) {
            self.cancelTokenSource.cancel('Request canceled due to new request.');
        }

        // Create a new cancel token for the current request
        self.cancelTokenSource = axios.CancelToken.source();

        try {
            let params = {
                filters: $wire.filters,
                sort: $wire.sort,
                debug: getUrlParam('debug'),
            };

            const response = await Promise.resolve(
                axios.post($wire.layersEndpoint, params, {
                    cancelToken: self.cancelTokenSource.token,
                }),
            );

            self.fetchingPoints = false;
            self.viewportMarkers = [];

            self.buildPins(response.data.results);
            self.buildPageIndexes(response.data.results);

            self.processingPoints = false;
        } catch (error) {
            console.error(error);
        }
    },

    getBoundsArray() {
        return self.map
            .getBounds()
            .toBBoxString()
            .split(',')
            .map(num => Number.parseFloat(num).toFixed(6));
    },

    buildPageIndexes(pins) {
        self.pages = chunk(map(pins, 0), $wire.meta.per_page);
        self.index = reduce(
            self.pages,
            (acc, page, index) => reduce(page, (acc, id) => set(acc, String(id), index), acc),
            [],
        );
    },

    buildPins(pins) {
        let index = 0;

        if (typeof pins == 'undefined' || !pins?.length) {
            return false;
        }

        pins.some(pin => {
            if (self.fetchingPoints) {
                return true; //brake
            }

            const marker = getProjectMarker(pin);

            if (!marker) {
                return false; //continue
            }

            marker.openPopup = async _marker => {
                const pp = new L.Rrose({
                    className: 'project-popup__main-container',
                    offset: new L.point({ x: 0, y: -50 }),
                    closeButton: true,
                    autoPan: true,
                    closeOnClick: true,
                    marker: _marker,
                });
                pp.marker = _marker;
                pp.setContent(await getProjectPopup($wire, pin[PROJECT_ID], self.mapDataExtras))
                    .setLatLng(_marker._latlng)
                    .openOn(self.map);
            };

            marker.on('mousedown', e => {
                if (e.target.popupOpen) {
                    e.target.popupOpen = false;
                    return;
                }

                self.goToListing(pin[PROJECT_ID]);
                if (!e.target.popupOpen) {
                    e.target.openPopup(e.target);
                }
            });

            self.activeLayerGroup.addLayer(marker);
            self.viewportMarkers[pin[PROJECT_ID]] = marker;
            index++;
        });
    },

    /* HELPERS START */
    async goToListing(id) {
        let pageIndex = self.index[id.toString()];
        if (pageIndex === undefined) {
            console.warn('Cannot find page of this project ', id);
            return;
        }

        if (self.scrollCardIntoView(id)) {
            return;
        }

        /* Change from 0-based to 1-based */
        const currentPage = pageIndex + 1;

        self.fetchPage(currentPage).then(() => self.scrollCardIntoView(id));
    },

    scrollCardIntoView(id) {
        if (!document) {
            return false;
        }

        const card = document.querySelector(`[data-cy="listing-section"] [data-cy="card-${id}"]`);

        if (!card) {
            return false;
        }

        card.scrollIntoView(self.scrollIntoViewOptions);

        return true;
    },

    async fetchPage(page) {
        const currentPage = getUrlParam('page') || 1;
        if (currentPage === page) {
            return;
        }

        document.getElementById('scrollableList').scrollTo(0, 0);

        self.fetchingPage = true;

        if (page > 1) {
            setUrlParam('page', page, true);
        } else {
            unsetUrlParam('page', true);
        }

        await $wire.refreshProjects(page);
        self.fetchingPage = false;
    },

    scrollIntoViewOptions() {
        return {
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
        };
    },

    setZoomScale(zoomLevel) {
        var leafletContainer = document.querySelector('.leaflet-container');
        if (leafletContainer) {
            if (zoomLevel > 14) {
                leafletContainer.classList.remove('zoom_14');
            } else {
                leafletContainer.classList.add('zoom_14');
            }
        }
    },
});
