import isempty from 'lodash.isempty';
import locationIcon from '@resources/svg/pin-gray.svg';
import metroIcon from '@resources/svg/metro.svg';

const LISTING_ID = 0;

export default function useListingCardPopup() {
    const getListingPopup = async (listingId, dataExtras, getPopupData) => {
        const popupData = await getPopupData(listingId);
        const listing = popupData.listing;

        if (isempty(listing)) {
            console.warn('Cannot find information about this listing', listingId);
            return false;
        }
        const firstPublishedAt = new Date(listing.first_published_at);
        const newListingBadge =
            (new Date() - firstPublishedAt) / (1000 * 60 * 60) < 48
                ? `<div class="listing-popup__announce-badge">${popupData.translations['tooltip_new_ad']}</div>`
                : '';

        const coverImage = listing.cover_image || dataExtras.defaultImage;

        const isInteger = value => {
            return Number.isInteger(parseInt(value));
        };

        if (!!listing.data_properties?.area_unit?.value) {
            dataExtras.squareMetresLabel = listing.data_properties?.area_unit?.value;
        }

        return `
        <div class="listing-popup__container">
            <div class="listing-popup__image__header">
                <a
                href='${listing.path}'
                target="_blank"
                x-on:click.prevent="redirectToListing('${listing.path}', '_blank')"
                >
                    <img class="listing-popup__splash-image" alt="Listing-${listingId}" src="${coverImage}" />
                    <span class="listing-popup__location-estimate-text">${dataExtras.locationEstimate}</span>
                    ${newListingBadge}
                </a>

                <div class="font-white absolute right-2 top-2 flex flex-col cursor-pointer items-center justify-center rounded-2xl bg-black bg-opacity-70 w-12 min-h-12 h-auto p-2 md:px-3 md:py-2"
                    x-data="{isFavourite: ${popupData.initialIsFavorite ? 'true' : 'false'}}"
                    x-init="await addPendingFavourite(${listingId}, $dispatch, '${popupData.show_login_page}', '${popupData.login_redirect_url}', '${popupData.full_url}')"
                    x-on:click.prevent="isFavourite = await toggleFavourite(${listingId}, isFavourite, $dispatch, '${popupData.show_login_page}', '${popupData.login_redirect_url}', '${popupData.full_url}')"
                >
                    <div class="flex items-center">
                        <svg x-show="isFavourite" class="favouite-icon cursor-pointer" fill="white" width="27" height="25" x-cloak
                            data-cy="{{ $this->dataCy }}"
                            data-bi="{{ $this->dataBi }}"
                            :key="'${listingId}_favourite_saved'">
                            <path d="M8.147.905c-2.231.198-4.293 1.33-5.598 3.073C1.097 5.916.541 8.589 1.037 11.25c.194 1.045.542 2.009 1.172 3.251.836 1.65 1.926 3.084 3.384 4.454 2.11 1.981 3.817 3.338 6.13 4.871 1.281.85 1.63 1.059 1.765 1.059.145 0 .375-.123 1.137-.608 2.618-1.668 5.252-3.754 7.218-5.72 1.597-1.596 2.739-3.327 3.56-5.396.543-1.369.78-2.707.729-4.116-.104-2.9-1.389-5.373-3.542-6.815-2.075-1.39-4.728-1.732-7.13-.919a8.533 8.533 0 0 0-1.664.781c-.136.087-.269.158-.296.158-.027 0-.16-.071-.296-.158-1.011-.647-2.324-1.075-3.641-1.189a6.267 6.267 0 0 0-1.416.002"/>
                        </svg>

                        <svg x-show="!isFavourite" class="favouite-icon cursor-pointer" fill="white" width="27" height="25"
                            data-cy="{{ $this->dataCy }}"
                            data-bi="{{ $this->dataBi }}"
                            :key="'${listingId}_favourite_unsaved'">
                            <path d="M8.147.905c-2.231.198-4.293 1.33-5.598 3.073C1.097 5.916.541 8.589 1.037 11.25c.194 1.045.542 2.009 1.172 3.251.836 1.65 1.926 3.084 3.384 4.454 2.11 1.981 3.817 3.338 6.13 4.871 1.281.85 1.63 1.059 1.765 1.059.145 0 .375-.123 1.137-.608 2.618-1.668 5.252-3.754 7.218-5.72 1.597-1.596 2.739-3.327 3.56-5.396.543-1.369.78-2.707.729-4.116-.104-2.9-1.389-5.373-3.542-6.815-2.075-1.39-4.728-1.732-7.13-.919a8.533 8.533 0 0 0-1.664.781c-.136.087-.269.158-.296.158-.027 0-.16-.071-.296-.158-1.011-.647-2.324-1.075-3.641-1.189a6.267 6.267 0 0 0-1.416.002m1.855 1.057a7.023 7.023 0 0 1 2.977 1.177c.482.341.541.338 1.128-.056a8.64 8.64 0 0 1 1.577-.801c.668-.238 1.34-.358 2.142-.381.854-.025 1.403.027 2.047.194 2.128.552 3.761 1.971 4.625 4.02.86 2.04.855 4.429-.014 6.651a14.851 14.851 0 0 1-3.52 5.257 42.14 42.14 0 0 1-6.002 4.833c-.544.362-1.094.722-1.221.801l-.232.142-.731-.47c-2.466-1.587-4.775-3.415-6.699-5.301-1.28-1.255-2.066-2.285-2.847-3.734-1.178-2.182-1.585-4.198-1.271-6.284.427-2.83 2.188-4.981 4.744-5.795 1.076-.342 2.087-.42 3.297-.253m7.982 4.187c-.144.144-.226.415-.17.563.058.152.166.229.546.391 1.052.449 1.694 1.34 1.867 2.591.057.411.366.579.762.413.264-.11.301-.431.131-1.13-.314-1.293-1.259-2.383-2.457-2.833-.391-.146-.529-.145-.679.005" fill-rule="evenodd"/>
                        </svg>
                    </div>
                </div>
            </div>

            <div class="listing-popup__content">
                <div class="listing-popup__content-price-container">
                    <span class="listing-popup__content-price">
                        ${listing.price}
                    </span>
                </div>

                <span class="listing-popup__content-location">
                    <img class="-mt-1" width="16px" height="16px" src="${locationIcon}" alt="location">
                    <span class="mr-1">${listing.address}</span>
                    <span class="whitespace-nowrap" x-show="${listing.data_properties?.shortest_metro_distance?.value}">
                        <img class="-mt-1" src="${metroIcon}" alt="metro" width="16px" height="16px">
                        ${listing.data_properties?.metro_distance_in_meters?.value} m
                        <span x-show="${listing.data_properties?.metro_distance_in_minutes?.value}">
                            (${listing.data_properties.metro_distance_in_minutes?.value} min)
                        </span>
                    </span>
                </span>

                <div class="listing-popup__content-badges">
                    <div x-show="${!!listing.data_properties?.bedroom_count
                        ?.value}" class='listing-popup__content-badge'>
                        ${parseInt(listing.data_properties?.bedroom_count?.value)}
                        ${dataExtras.roomLabel}
                    </div>

                    <div x-show="${!!listing.data_properties?.usable_surface
                        ?.value}" class='listing-popup__content-badge'>
                        ${parseInt(listing.data_properties?.usable_surface?.value)}
                        ${dataExtras.squareMetresLabel}
                    </div>

                    <div x-show="${
                        !listing.data_properties?.usable_surface?.value &&
                        !!listing.data_properties?.total_usable_surface?.value
                    }" class='listing-popup__content-badge'>
                        ${parseInt(listing.data_properties?.total_usable_surface?.value)}
                        ${dataExtras.squareMetresLabel}
                    </div>

                    <div x-show="${
                        !listing.data_properties?.usable_surface?.value &&
                        !!listing.data_properties?.land_area_square_meters?.value
                    }" class='listing-popup__content-badge'>
                        ${parseInt(listing.data_properties?.land_area_square_meters?.value)}
                        ${dataExtras.squareMetresLabel}
                    </div>

                    <div x-show="${
                        !listing.data_properties?.usable_surface?.value &&
                        !!listing.data_properties?.street_front_distance?.value
                    }" class='listing-popup__content-badge'>
                        ${parseInt(listing.data_properties?.street_front_distance?.value)}
                        ${dataExtras.metersLabel}
                    </div>

                    <div x-show="${!!listing.data_properties?.floor_number
                        ?.display_value}" class='listing-popup__content-badge'>
                        <span>${listing.data_properties?.floor_number?.display_value}</span>
                        <span x-show="${isInteger(listing.data_properties?.number_of_floors?.value)}">/ ${parseInt(
                            listing.data_properties?.number_of_floors?.value,
                        )}
                        </span>
                    </div>

                    <div x-show="${!!listing.data_properties?.land_classification
                        ?.display_value}" class='listing-popup__content-badge'>
                        ${listing.data_properties?.land_classification?.display_value}
                    </div>

                    <div x-show="${!!listing.data_properties?.height_condition
                        ?.display_value}" class='listing-popup__content-badge'>
                        ${listing.data_properties?.height_condition?.display_value}
                    </div>

                    <div x-show="${
                        !listing.data_properties?.usable_surface?.value &&
                        !!listing.data_properties?.building_type?.display_value
                    }" class='listing-popup__content-badge'>
                        ${listing.data_properties?.building_type?.display_value}
                    </div>
                </div>

                ${popupData.phoneButtonContent || ''}
            </div>
        </div>`;
    };

    return {
        getListingPopup,
    };
}
