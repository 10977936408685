import L from 'leaflet';
import Cookies from 'js-cookie';
import 'leaflet/dist/leaflet.css';
import '@resources/css/leaflet.rrose.css';
import '@resources/css/theme/imo/leaflet-map.css';
import 'leaflet-editable';
import '@resources/js/app/composables/leaflet/leaflet.rrose-src.js';
import '@resources/js/app/composables/leaflet/leaflet.patches.js';
import useUrl from '@resources/js/app/composables/useUrl.js';
import useCustomMarker from '@resources/js/app/composables/useCustomMarker.js';
import axios from 'axios';
import { round, floor, ceil, isEqual, debounce } from 'lodash';
import { booleanPointInPolygon, featureCollection, point, polygon, bboxClip } from '@turf/turf';
import FreeDraw, { CREATE, NONE } from 'leaflet-freedraw';
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

const { hasUrlParam, getUrlParam, setUrlParam, unsetUrlParam } = useUrl();
const { getMarkerByLayerType, getClusterMarker } = useCustomMarker();

const LISTING_ID = 0;
const LISTING_COORDINATES = 1;
const LISTING_HIDE_EXACT_LOCATION = 3;
const LISTING_PROMOTIONS = 4;
const LISTING_PROJECT_ID = 5;
const CLUSTER_SIZE = 8;
const PROJECT_LISTING_ID = 0;
const PROJECT_LISTINGS = 8;
const LISTING_IS_HOVER = 100;
const LISTING_IS_AGENCY = 101;

let self = null;

export default $wire => ({
    /* Map instance */
    map: null,
    maxBounds: null,
    mapDataExtras: [],

    /* Flags and tokens */
    fetchingPoints: false,
    loadingProgress: 1,
    hasMultipleTopLevelLocations: false,
    canDisplayProjects: true,

    /* Layers */
    clusterLayerGroup: null,
    promoLayerGroup: null,
    projectsLayerGroup: null,
    activeLayerGroup: null,
    drawnShapePoly: null,
    freeDraw: null,
    /* Pins data */
    index: [],
    pages: [],
    lastState: { length: 0, firstPage: [] },

    agencyPins: [],
    viewportLength: -1,
    viewportPins: [],
    viewportProjects: [],
    preventingInitialRefresh: true,

    hoverMarker: null,
    clickedMarkerId: null,
    sort: null,
    disableClusteringAtZoom: 17,
    showListingDetails: false,
    crsfToken: null,

    async init() {
        self = this;

        if (!$wire.showMap) {
            return true;
        }

        window.addPendingFavourite = self.addPendingFavourite;
        window.toggleFavourite = self.toggleFavourite;
        window.redirectToListing = self.redirectToListing;

        if (
            hasUrlParam('map-zoom') ||
            hasUrlParam('map-latitude') ||
            hasUrlParam('map-longitude') ||
            hasUrlParam('map-area')
        ) {
            self.preventingInitialRefresh = false;
        }

        self.sort = $wire.sort;

        Livewire.on('sortingUpdated', function (newVal) {
            newVal = newVal[0];
            self.sort = newVal;
            $wire.sort = newVal;
            $wire.dispatchTo('search.top-filters', 'fieldChanged', { field: 'sort', value: newVal });
            if (newVal === 'default') {
                unsetUrlParam('sort', true);
            } else {
                setUrlParam('sort', newVal, true);
            }
            self.refresh(true);
        });

        self.hasMultipleTopLevelLocations = await $wire.hasMultipleTopLevelLocations();
        self.canDisplayProjects = $wire.canDisplayProjects;

        const maxBounds = [
            [$wire.bounds[1], $wire.bounds[0]],
            [$wire.bounds[3], $wire.bounds[2]],
        ];

        self.debouncedRenderPins = debounce(self.renderPins, 600);
        self.debouncedRenderCards = debounce(self.renderCards, 600);

        document.getElementById('map').innerHTML = '';
        self.map = L.map('map', {
            zoom: $wire.zoom,
            center: $wire.center,
            maxZoom: $wire.maxZoom,
            minZoom: $wire.minZoom,
            maxBounds: maxBounds,
            zoomControl: false,
            fadeAnimation: true,
            markerZoomAnimation: true,
            zoomAnimation: true,
            zoomAnimationThreshold: 1,
            editable: true,
            doubleClickZoom: false,
            boxZoom: false,
            maxBoundsViscosity: 0.8,
        })
            .on('click', e => {
                self.setShowListingDetails(false);
                self.hideNoResultsPopup();
            })
            .on('movestart', e => {
                self.hideNoResultsPopup();
            })
            .on('moveend', async () => {
                self.closePopupNotInMapBounds();
                self.debouncedRenderPins();
                self.debouncedRenderCards(1);
                self.preventingInitialRefresh = false;
            })
            .on('zoomend', function (e) {
                self.setZoomScale(e.target._zoom);
            })
            .on('popupopen', e => {
                const m = e.popup.marker;
                self.hoverMarker = m;
                if (m._icon) {
                    L.DomUtil.addClass(m._icon, 'hover');
                }
                m.popupOpen = true;

                if (m?.options?.id) {
                    $wire.trackCardView(m.options.id);
                }

                const projectListingCards = document.querySelectorAll(
                    '.project-card__listings__container .project-card__listing__wrapper',
                );

                projectListingCards?.forEach(projectListingCard => {
                    projectListingCard.addEventListener('click', function (event) {
                        const listingId = projectListingCard.getAttribute('data-listing-id');
                        if (self.clickedMarkerId != listingId) {
                            event.preventDefault();
                            self.goToListing(listingId);
                        }
                        self.clickedMarkerId = listingId;
                    });
                });
            })
            .on('popupclose', e => {
                self.hoverMarker = null;
                document.querySelectorAll('.leaflet-marker-icon.hover').forEach(m => {
                    m.classList.remove('hover');
                });
                e.popup.marker.popupOpen = false;
                self.clickedMarkerId = null;
            });

        self.mapDataExtras = Object.assign({}, $wire.mapDataExtras);

        new L.tileLayer($wire.mapUrl, {
            opacity: 0.7,
            zIndex: 30,
            subdomains: $wire.subdomains,
            detectRetina: false,
        }).addTo(self.map);

        L.control
            .zoom({
                position: 'bottomright',
            })
            .addTo(self.map);

        self.setZoomScale(self.map.getZoom());
        self.renderEditControls();
        self.map.addControl(self.newPolygonButton);
        self.clusterLayerGroup = L.featureGroup();
        self.promoLayerGroup = L.layerGroup();
        self.projectsLayerGroup = L.layerGroup();
        self.activeLayerGroup = L.layerGroup();
        self.map.addLayer(self.activeLayerGroup);

        self.$watch('fetchingPoints', value => {
            if (value === true) {
                if (self.progressInterval) {
                    return;
                }
                self.progressInterval = setInterval(() => {
                    self.loadingProgress = Math.min(self.loadingProgress + 5, 100);
                }, 200);
            }
            if (value === false) {
                clearInterval(self.progressInterval);
                self.progressInterval = null;
                self.loadingProgress = 0;
            }
        });

        if ($wire.polygon.length > 2) {
            self.drawnShapePoly = L.polygon($wire.polygon, self.polylineOptions()).addTo(self.map);
            self.drawnShapePoly.enableEdit();
            self.map.removeControl(this.newPolygonButton);
            self.map.addControl(this.resetPolygonButton);
            await self.commitPolygon();
        } else {
            await self.refresh();
        }

        Livewire.on('fetchPage', function (page) {
            page = parseInt(page);
            if (page > 1 && page <= Math.floor($wire.openSearchMaxDocuments / $wire.meta.per_page)) {
                setUrlParam('page', page, true);
                $wire.currentPageFromUrl = page;
            } else {
                unsetUrlParam('page', true);
                $wire.currentPageFromUrl = 1;
            }
            self.debouncedRenderCards(page, null, true);
        });

        // Listing card hover events
        if (!self.isMobile) {
            self.cachedResponse = [];
            self.listingsEndpoint = await $wire.getLayersEndpoints(['listing']);
            self.hoverCardId = null;
            self.fakeMarker = null;

            window.addEventListener('mouseOverListingCard', async function (e) {
                self.hoverCardId = e.detail.listing_id;

                let pin;
                if (self.cachedResponse && self.cachedResponse[e.detail.listing_id]) {
                    pin = self.cachedResponse[e.detail.listing_id];
                } else {
                    const params = {
                        filters: {
                            id: e.detail.listing_id,
                        },
                    };
                    try {
                        self.hovelCancelTokenFetch?.cancel('Request canceled due to new request.');
                        self.hovelCancelTokenFetch = axios.CancelToken.source();
                        const api = axios.create({
                            headers: {
                                'X-CSRF-TOKEN': self.crsfToken,
                                'Content-Type': 'application/json',
                            },
                        });
                        const response = await api.post(self.listingsEndpoint.listing, params, {
                            cancelToken: self.hovelCancelTokenFetch.token,
                        });
                        pin = self.cachedResponse[e.detail.listing_id] = response.data.results;
                    } catch (error) {
                        pin = null;
                    }
                }

                if (!self.hoverCardId || self.hoverCardId != e.detail.listing_id) {
                    return;
                }

                const projectId = !!pin ? (pin[LISTING_PROJECT_ID] ?? null) : null;
                if (projectId && !!self.viewportProjects[projectId]) {
                    const projectMarker = self.viewportProjects[projectId].marker;
                    if (projectMarker && !projectMarker.popupOpen) {
                        projectMarker.openPopup(projectMarker);
                    }
                } else if (pin) {
                    pin[LISTING_IS_HOVER] = true;
                    self.fakeMarker = self.buildMarker(pin, 'listings');
                    self.activeLayerGroup.clearLayers();
                    self.activeLayerGroup.addLayer(self.fakeMarker);
                }
            });

            window.addEventListener('mouseLeaveListingCard', function (e) {
                self.hoverCardId = null;

                if (self.hoverMarker?.options?.id == e.detail.listing_id) {
                    return false;
                }

                if (!document.querySelector('.project-card__listing__list-wrapper')?.classList?.contains('expand')) {
                    self.map.closePopup();
                }

                self.activeLayerGroup.clearLayers();
                self.fakeMarker = null;
            });
        }

        // Open listing details by default if favorite is pending
        let storageListingId = JSON.parse(localStorage.getItem('storePendingFavorite'));
        if (storageListingId) {
            await self.selectEntity(storageListingId, 'listing');
            self.setShowListingDetails(true);
        }
    },

    async refresh(forceRequest = false) {
        self.debouncedRenderPins();
        if (self.preventingInitialRefresh && !forceRequest) {
            // If first page load or viewport didn't changed, center the map
            if (self.hasMultipleTopLevelLocations || Boolean(self.getPoly())) {
                if (self.clusterLayerGroup?.getLayers().length) {
                    const pinsBounds = self.clusterLayerGroup.getBounds();
                    if (Object.keys(pinsBounds).length) {
                        self.map.fitBounds(pinsBounds, { animate: false });
                    }
                }
            }

            return;
        }

        self.debouncedRenderCards(getUrlParam('page') ?? 1, null, forceRequest);
    },

    debounceSetViewportFilters: debounce((newCenter, newMapZoom) => {
        $wire.dispatchTo('search.top-filters', 'updateFields', {
            params: {
                'map-zoom': newMapZoom,
                'map-latitude': newCenter.lat,
                'map-longitude': newCenter.lng,
            },
        });
    }, 2000),

    /**
     * Refetch listing cards
     */
    async renderCards(page = 1, listingId = null, forceRequest = false, afterFetch = null) {
        const newCenter = self.map.getCenter();
        const newMapZoom = self.map.getZoom();
        setUrlParam('map-latitude', newCenter.lat, true);
        setUrlParam('map-longitude', newCenter.lng, true);
        setUrlParam('map-zoom', newMapZoom, true);

        if (self.isMobile) {
            self.debounceSetViewportFilters(newCenter, newMapZoom);
            return false;
        }
        const boundingBox = self.preventingInitialRefresh ? [] : self.getBoundsArray(4);

        if (forceRequest || !isEqual(self.lastCardsBoundingBox, boundingBox)) {
            self.fetchingPage = true;

            if (document.getElementById('scrollableList')) {
                document.getElementById('scrollableList').scrollTo(0, 0);
                if (page == 1) {
                    unsetUrlParam('page', true);
                    $wire.currentPageFromUrl = 1;
                }

                await $wire.refreshCards(
                    boundingBox,
                    newCenter,
                    newMapZoom,
                    window.location.href,
                    page,
                    listingId,
                    self.getPoly() ? 1 : 0,
                );

                if (afterFetch) {
                    afterFetch();
                }
            }

            self.fetchingPage = false;
        }

        self.lastCardsBoundingBox = boundingBox;
    },

    /**
     * Refetch listing pins
     */
    async renderPins() {
        let types = ['top_listing', 'top_listing_s', 'top_listing_energy', 'listings_projects'];
        let decimals = 3;
        if (!self.getPoly()) {
            decimals = Math.max(
                0,
                Math.min(
                    3,
                    Math.floor(
                        ((self.map.getZoom() - self.map.getMinZoom()) /
                            (self.map.getMaxZoom() - self.map.getMinZoom())) *
                            3,
                    ),
                ),
            );
        }
        const roundedBoundingBox = self.getBoundsArray(self.getPoly() ? 4 : decimals);
        const preciseBoundingBox = self.getBoundsArray(4);

        if (
            !isEqual(self.lastRoundedBoundingBox, roundedBoundingBox) ||
            !isEqual(self.lastPreciseBoundingBox, preciseBoundingBox)
        ) {
            if (!isEqual(self.lastRoundedBoundingBox, roundedBoundingBox)) {
                types.unshift('listings');
            }
            if (!self.lastRoundedBoundingBox) {
                types.unshift('agency_listings');
            }
            await self.fetchPins(roundedBoundingBox, preciseBoundingBox, types);
        }

        self.countPinsInViewport();

        self.lastRoundedBoundingBox = roundedBoundingBox;
        self.lastPreciseBoundingBox = preciseBoundingBox;
    },

    /**
     * Get the list of pins from the server.
     * @param {object} boundingBox - List of points that defins a rectangle.
     * @param {object} polygon - List of points that defins a polygon.
     */
    async fetchPins(roundedBoundingBox, preciseBoundingBox, types) {
        self.fetchingPoints = true;

        self.cancelTokenDraw = Math.random();
        const cancelTokenDraw = self.cancelTokenDraw;

        /* Cancel the previous requests if exists */
        self.cancelTokenFetch?.cancel('Request canceled due to new request.');
        self.cancelTokenFetch = axios.CancelToken.source();

        const api = axios.create({
            headers: {
                'X-CSRF-TOKEN': self.crsfToken,
                'Content-Type': 'application/json',
            },
        });

        try {
            const requests = types
                .map(type => {
                    const url = $wire.layersEndpoints[type];
                    const zoomRule = self.getZoomRules(type);
                    const clusterize = self.map.getMaxZoom() - self.map.getZoom() >= 2 ? true : false;
                    const params = {
                        filters: $wire.filters,
                        boundingBox: type === 'listings' ? roundedBoundingBox : preciseBoundingBox,
                        clusterize: type === 'listings' ? clusterize : false,
                        zoom: self.map.getZoom(),
                        polygon: self.getPoly() ? 1 : 0,
                        sort: self.sort,
                        size: zoomRule,
                    };

                    if (zoomRule !== 0) {
                        return {
                            req: api
                                .post(url, params, {
                                    cancelToken: self.cancelTokenFetch.token,
                                })
                                .catch(error => {}),
                            type: type,
                        };
                    }
                })
                .filter(el => el !== undefined);

            let responses = await Promise.all(requests.map(r => r.req));

            /* Prevent drawing pins if another request was made */
            if (self.cancelTokenDraw !== cancelTokenDraw) {
                return;
            }

            // Sometimes the server might return an empty response due to temporary issues or delays. This retry mechanism ensures that we attempt to fetch the listings again up to 4 times to increase the chances of getting a valid response before giving up.
            for (let i = 0; i < 4; i++) {
                const retry = requests.some((v, i) => {
                    return v.type === 'listings' && !responses[i]?.data?.results;
                });

                if (retry) {
                    await self.setCrsfToken();
                    responses = await Promise.all(requests.map(r => r.req));
                } else {
                    break;
                }
            }

            /* Prevent drawing pins if another request was made */
            if (self.cancelTokenDraw !== cancelTokenDraw) {
                return;
            }

            self.fetchingPoints = false;

            /* Remove pins layer */
            if (types.includes('listings')) {
                self.map.removeLayer(self.clusterLayerGroup);
                self.clusterLayerGroup.clearLayers();
                self.viewportPins = [];
            }

            self.map.removeLayer(self.promoLayerGroup);
            self.map.removeLayer(self.projectsLayerGroup);
            self.promoLayerGroup.clearLayers();
            self.projectsLayerGroup.clearLayers();
            self.activeLayerGroup.clearLayers();
            self.viewportProjects = [];

            responses.some((response, index) => {
                if (!response) {
                    return;
                }
                const type = requests[index]['type'];
                if (type === 'agency_listings') {
                    self.agencyPins = [].concat(...(response.data.result || []));
                } else {
                    self.buildPins(response.data.results, type);
                }
            });

            /* Add pins layer back */
            if (types.includes('listings')) {
                self.map.addLayer(self.clusterLayerGroup);
            }
            self.map.addLayer(self.promoLayerGroup);
            self.map.addLayer(self.projectsLayerGroup);
        } catch (error) {
            self.fetchingPoints = false;
            console.warn(error);
        }
    },

    /**
     * Create pins based on it's promotion type and add them to coresponding layer.
     * Handles popup logic.
     * @param {array} pins - List of pins
     * @param {string} type - Pins type
     * @param {string} layer - Layer where to draw pins
     */
    buildPins(pins, type) {
        if (typeof pins === 'undefined' || !pins?.length) {
            return false; //continue
        }

        pins.some(pin => {
            let _type = type;

            if (self.fetchingPoints) {
                return true; //break
            }

            /* Dont display normal pin if it is part of a project */
            if (_type !== 'listings_projects' && pin[LISTING_PROJECT_ID] && self.canDisplayProjects) {
                return false; //continue
            }

            if (_type === 'listings_projects' && !pin[PROJECT_LISTINGS].length) {
                return false; //continue
            }

            /* Parse lisings respond */
            if (_type === 'listings') {
                if (parseInt(pin[CLUSTER_SIZE]) > 1) {
                    _type = 'clusters';
                }
            }

            const marker = self.buildMarker(pin, _type);
            if (!marker) {
                return false; //continue
            }

            if (_type === 'listings_projects') {
                self.viewportProjects[pin[PROJECT_LISTING_ID]] = {
                    ...pin,
                    ...{ marker: marker },
                };
            } else if (_type !== 'clusters') {
                self.viewportPins[pin[LISTING_ID]] = {
                    ...pin,
                    ...{ marker: marker },
                };
            }

            if (_type === 'listings' || _type === 'clusters') {
                self.clusterLayerGroup.addLayer(marker);
            } else if (_type === 'listings_projects') {
                self.projectsLayerGroup.addLayer(marker);
            } else {
                self.promoLayerGroup.addLayer(marker);
            }
        });
    },

    /**
     * Generates the pin marker.
     * @param {object} pin - Pin info.
     * @param {string|null} type - Layer type.
     */
    buildMarker(pin, type = null) {
        if (pin[LISTING_COORDINATES]?.length !== 2) {
            return false;
        }

        pin[LISTING_PROMOTIONS] = pin[LISTING_PROMOTIONS] ?? [];
        pin[LISTING_PROMOTIONS].push(type);

        if (self.hoverMarker?.options?.id === pin[LISTING_ID]) {
            pin[LISTING_IS_HOVER] = true;
        }

        if (self.agencyPins.includes(pin[LISTING_ID])) {
            pin[LISTING_IS_AGENCY] = true;
        }

        let marker = null;
        switch (type) {
            case 'clusters':
                marker = getClusterMarker(pin, self.map, self.isMobile);
                break;
            default:
                const extraData = {
                    ...self.mapDataExtras,
                    locationEstimate: pin[LISTING_HIDE_EXACT_LOCATION]
                        ? $wire.translations['tooltip_approximate_location']
                        : $wire.translations['tooltip_exact_location'],
                    saveProjectMapRaoLogs: $wire.saveProjectMapRaoLogs,
                    getPopupData: $wire.getPopupData,
                };

                marker = getMarkerByLayerType(
                    pin,
                    type,
                    self.map,
                    extraData,
                    self.isMobile,
                    self.setShowListingDetails,
                    self.selectEntity,
                );
                if (type !== 'listings_projects') {
                    marker.on('click', function (e) {
                        self.goToListing(e.target?.options?.id);
                    });
                }
                break;
        }

        return marker;
    },

    setShowListingDetails(newValue, hoverMarker = null) {
        self.showListingDetails = newValue;
        document.querySelectorAll('.leaflet-marker-icon.hover').forEach(m => {
            m.classList.remove('hover');
        });
        self.hoverMarker = null;

        if (newValue) {
            self.hoverMarker = hoverMarker;
            if (hoverMarker?._icon) {
                L.DomUtil.addClass(hoverMarker._icon, 'hover');
            }
            if (hoverMarker?.options?.id) {
                $wire.trackCardView(hoverMarker.options.id);
            }
        }
    },

    hideNoResultsPopup() {
        if (self.viewportLength == 0) {
            self.viewportLength = -1;
        }
    },

    async selectEntity(id, type) {
        await $wire.selectEntity(id, type, self.getBoundsArray(4), self.getPoly() ? 1 : 0);
    },

    initProjectDetailsSwiper(selector, listingsCount) {
        self.projectDetailsSwiper?.destroy();
        if (listingsCount <= 1) {
            return;
        }
        self.projectDetailsSwiper = new Swiper(selector, {
            slidesPerView: listingsCount > 1 ? 1.15 : 1,
            spaceBetween: 10,
            navigation: {
                nextEl: '.next-project-card',
                prevEl: '.prev-project-card',
            },
        });
    },

    initDrawing() {
        if (self.freeDraw) {
            self.freeDraw.mode(CREATE);
            return;
        }

        self.freeDraw = new FreeDraw({ strokeColor: '#048ba8', mode: CREATE });
        self.freeDraw.addTo(self.map);
        self.map.fixTouchEvents();

        self.freeDraw.on('markers', function (event) {
            if (event.eventType == 'create' && event.latLngs.length > 0) {
                var latLngs = event.latLngs[0];

                if (self.drawnShapePoly) {
                    self.map.removeLayer(self.drawnShapePoly);
                }

                self.drawnShapePoly = L.polygon(
                    latLngs.map(function (latLng) {
                        return [latLng.lat, latLng.lng];
                    }),
                    self.polylineOptions(),
                );

                self.map.addLayer(self.drawnShapePoly);
                self.drawnShapePoly.enableEdit();
                self.commitPolygon();

                self.freeDraw.clear();
                self.freeDraw.mode(NONE);
            }
        });
        // FREE DRAW END
    },

    renderEditControls() {
        L.EditControl = L.Control.extend({
            options: {
                position: this.isLowerThen('xl') ? 'bottomright' : 'bottomleft',
                callback: null,
                kind: '',
                html: '',
            },
            onAdd: function (map) {
                let container = L.DomUtil.create(
                    'div',
                    'xl:flex bottom-[0px] xl:bottom-[10px] !mx-4 xl:!mx-0 left-0 xl:left-[154px] z-100 space-x-4',
                );
                let link = L.DomUtil.create(
                    'button',
                    'flex items-center justify-center xl:justify-start xl:space-x-3 bg-secondary xl:bg-black xl:bg-opacity-80 text-white text-sm font-semibold leading-none xl:leading-6 rounded-xl px-0 xl:px-4 w-[36px] xl:w-auto h-[36px] xl:h-10',
                    container,
                );

                link.title = this.options.kind;
                link.innerHTML = this.options.html;
                L.DomEvent.on(link, 'click', L.DomEvent.stop).on(
                    link,
                    'click',
                    function () {
                        window.LAYER = this.options.callback.call(map.editTools);
                    },
                    this,
                );

                return container;
            },
        });

        const leafletControlsContainer = document.querySelector('.leaflet-control-container');
        if (leafletControlsContainer) {
            leafletControlsContainer.addEventListener('mousedown', function (event) {
                L.DomEvent.stopPropagation(event);
            });
        }

        const exclamationIcon = `<svg width="18" height="18" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 36C22.8115 36 27.3334 34.128 30.7299 30.7299C34.128 27.3334 36 22.8115 36 18C36 8.07496 27.925 0 18 0C13.1885 0 8.66661 1.872 5.27009 5.27009C1.872 8.66661 0 13.1885 0 18C0 27.925 8.07496 36 18 36ZM6.37593 6.37658C9.47819 3.27432 13.6057 1.5651 17.9992 1.5651C27.0618 1.5651 34.434 8.93727 34.434 17.9999C34.434 22.3934 32.7248 26.5209 29.6225 29.6232C26.5203 32.7255 22.3928 34.4347 17.9992 34.4347C8.93663 34.4347 1.56445 27.0625 1.56445 17.9999C1.56445 13.6063 3.27367 9.47884 6.37593 6.37658Z" fill="#048BA8"/>
        <path d="M18.0002 10.5898C17.4158 10.5898 16.9414 10.3527 16.9414 10.0604V9.00161C16.9414 8.70937 17.4158 8.4722 18.0002 8.4722C18.5847 8.4722 19.0591 8.70937 19.0591 9.00161V10.0604C19.0591 10.3527 18.5847 10.5898 18.0002 10.5898Z" fill="#048BA8"/>
        <path d="M18.0002 27.5273C17.4158 27.5273 16.9414 27.1953 16.9414 26.7862V13.445C16.9414 13.0359 17.4158 12.7038 18.0002 12.7038C18.5847 12.7038 19.0591 13.0359 19.0591 13.445V26.7862C19.0591 27.1953 18.5847 27.5273 18.0002 27.5273Z" fill="#048BA8"/>
        </svg>`;

        const tutorialBox = `<p class="pointer-events-none drawing-tutorial-box fixed xl:absolute flex items-center justify-center h-9 xl:h-10 bg-white p-2 rounded-xl right-[60px] xl:right-auto xl:left-[100%] xl:ml-2 w-max drop-shadow-[0_5px_5px_rgba(0,0,0,.2)] text-xs"><span class="mr-1">${exclamationIcon}</span><span class="text-black font-normal">${$wire.translations['draw_tutorial']}</span></p>`;

        const mobileDrawIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_16270_55156)">
            <path d="M14.2166 0.290812C13.8393 -0.0865208 13.1799 -0.0865208 12.8026 0.290812L0.827917 12.2648C0.786584 12.3061 0.76325 12.3561 0.747917 12.4088C0.745917 12.4148 0.739917 12.4195 0.738584 12.4255L0.00858347 15.5895C-0.0174165 15.7015 0.0165835 15.8188 0.0979168 15.9001C0.16125 15.9635 0.246583 15.9981 0.333917 15.9981C0.358584 15.9981 0.383917 15.9955 0.408583 15.9895L3.57258 15.2595C3.57925 15.2581 3.58325 15.2521 3.58925 15.2501C3.64125 15.2348 3.69192 15.2115 3.73325 15.1701L14.1046 4.79948C14.1106 4.79481 14.1173 4.79281 14.1233 4.78748C14.1293 4.78215 14.1306 4.77481 14.1353 4.76881L15.7079 3.19615C15.8966 3.00748 16.0006 2.75615 16.0006 2.48881C16.0006 2.22215 15.8966 1.97081 15.7079 1.78148L14.2166 0.290812ZM13.4126 4.54815L3.49792 14.4635L2.75525 13.7208L12.6673 3.80281L13.4126 4.54815ZM2.28392 13.2495L1.53525 12.5008L11.4499 2.58615L12.1959 3.33148L2.28392 13.2495ZM1.25325 13.1615L2.83658 14.7448L0.777917 15.2201L1.25325 13.1615ZM15.2366 2.72481L13.8846 4.07681L12.9039 3.09615C12.9039 3.09548 12.9039 3.09548 12.9033 3.09548L11.9226 2.11481L13.2753 0.762146C13.4013 0.636813 13.6206 0.636813 13.7466 0.762146L15.2373 2.25281C15.2993 2.31681 15.3339 2.40015 15.3339 2.48881C15.3339 2.57815 15.2993 2.66215 15.2366 2.72481Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_16270_55156">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>`;

        const desktopDrawIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.44942 8.09168V14.7999C9.44942 14.9458 9.50736 15.0857 9.61051 15.1888C9.71366 15.292 9.85355 15.3499 9.99942 15.3499C10.1453 15.3499 10.2852 15.292 10.3883 15.1888C10.4915 15.0857 10.5494 14.9458 10.5494 14.7999V8.09168C11.4501 7.95816 12.2732 7.50107 12.863 6.80347C13.4818 6.07158 13.7969 5.13053 13.7436 4.17357C13.6903 3.21661 13.2726 2.3164 12.5762 1.6578C11.8799 0.999208 10.9579 0.632227 9.99942 0.632227C9.04098 0.632227 8.11891 0.999208 7.4226 1.6578C6.72628 2.3164 6.30856 3.21661 6.25523 4.17357C6.2019 5.13053 6.51701 6.07158 7.13584 6.80347C7.72568 7.50107 8.54878 7.95816 9.44942 8.09168ZM9.99942 1.74994C10.5235 1.74994 11.0359 1.90536 11.4717 2.19654C11.9075 2.48773 12.2471 2.9016 12.4477 3.38582C12.6483 3.87005 12.7008 4.40287 12.5985 4.91692C12.4962 5.43097 12.2439 5.90316 11.8733 6.27377C11.5026 6.64438 11.0305 6.89677 10.5164 6.99902C10.0024 7.10127 9.46953 7.04879 8.98531 6.84822C8.50108 6.64764 8.08721 6.30799 7.79602 5.8722C7.50485 5.43642 7.34943 4.92409 7.34942 4.4C7.35023 3.69741 7.62969 3.02382 8.1265 2.52701C8.62333 2.03018 9.29696 1.75072 9.99959 1.74994L9.99942 1.59994V1.74994Z" fill="white" stroke="white" stroke-width="0.3"/>
            <path d="M12.3501 13.0561L12.3502 13.0561C14.2291 13.2255 15.7227 13.5814 16.7419 13.9975C17.2521 14.2059 17.6368 14.4266 17.8912 14.6415C18.1499 14.86 18.2498 15.0514 18.2498 15.2C18.2498 15.3729 18.1056 15.609 17.732 15.8758C17.3679 16.1358 16.8213 16.3987 16.1049 16.6333C14.6736 17.1021 12.5899 17.45 9.9998 17.45C7.40968 17.45 5.32597 17.1021 3.89472 16.6333C3.17827 16.3987 2.63169 16.1358 2.26756 15.8758C1.89397 15.609 1.7498 15.3729 1.7498 15.2C1.7498 15.0514 1.84975 14.86 2.10843 14.6415C2.36276 14.4266 2.74744 14.2059 3.25769 13.9975C4.27682 13.5814 5.7703 13.2255 7.64919 13.0561L7.64922 13.0561C7.72117 13.0495 7.79112 13.0289 7.8551 12.9954C7.91908 12.9619 7.97583 12.9161 8.0221 12.8606C8.06837 12.8051 8.10327 12.7411 8.1248 12.6721C8.14632 12.6032 8.15406 12.5307 8.14756 12.4587L7.99816 12.4722L8.14756 12.4587C8.14106 12.3868 8.12045 12.3168 8.08691 12.2528C8.05338 12.1889 8.00757 12.1321 7.9521 12.0858C7.89663 12.0396 7.83259 12.0047 7.76364 11.9831C7.69469 11.9616 7.62217 11.9539 7.55023 11.9604C5.98248 12.1017 4.27609 12.4185 2.95703 12.9341C2.29798 13.1917 1.72638 13.5025 1.31717 13.8731C0.906508 14.2451 0.649805 14.687 0.649805 15.2C0.649805 15.7835 0.984003 16.2824 1.51835 16.6955C2.05235 17.1084 2.80319 17.45 3.68283 17.7227C5.44373 18.2686 7.75957 18.55 9.9998 18.55C12.24 18.55 14.5559 18.2686 16.3168 17.7227C17.1964 17.45 17.9473 17.1084 18.4813 16.6955C19.0156 16.2824 19.3498 15.7835 19.3498 15.2C19.3498 14.687 19.0931 14.2451 18.6824 13.8731C18.2731 13.5025 17.7015 13.1917 17.0424 12.9341C15.7233 12.4185 14.0169 12.1017 12.4491 11.9604C12.3038 11.9473 12.1593 11.9924 12.0473 12.0858C11.9352 12.1793 11.8649 12.3134 11.8518 12.4587C11.8387 12.604 11.8838 12.7486 11.9773 12.8606C12.0707 12.9726 12.2049 13.0429 12.3501 13.0561Z" fill="white" stroke="white" stroke-width="0.3"/>
        </svg> <span class="hidden xl:block">${$wire.translations['draw_area']}</span>`;

        L.NewLineControl = L.EditControl.extend({
            options: {
                position: this.isLowerThen('xl') ? 'bottomright' : 'bottomleft',
                callback: () => {
                    self.initDrawing();
                    $wire.trackDrawEvent('edit');
                    self.map.removeControl(this.newPolygonButton);
                    self.map.addControl(this.resetPolygonButton);
                },
                kind: 'new_polyline',
                html: this.isLowerThen('xl') ? mobileDrawIcon : desktopDrawIcon,
            },
        });

        const mobileCloseIcon = `${tutorialBox} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 20 20">
            <path d="M20 2.014 17.986 0 10 7.986 2.014 0 0 2.014 7.986 10 0 17.986 2.014 20 10 12.014 17.986 20 20 17.986 12.014 10 20 2.014Z"/>
        </svg>`;

        const desktopCloseIcon = `${tutorialBox} ${$wire.translations['reset_area']}`;

        L.ResetPolygonControl = L.EditControl.extend({
            options: {
                position: this.isLowerThen('xl') ? 'bottomright' : 'bottomleft',
                callback: () => {
                    self.freeDraw?.mode(NONE);
                    this.resetPolygon();
                    $wire.trackDrawEvent('reset');
                },
                kind: 'reset_polygon',
                html: this.isLowerThen('xl') ? mobileCloseIcon : desktopCloseIcon,
            },
        });

        this.newPolygonButton = new L.NewLineControl();
        this.resetPolygonButton = new L.ResetPolygonControl();

        this.map.on('editable:drawing:end', e => {
            this.commitPolygon();
        });

        this.map.on('editable:vertex:clicked editable:vertex:dragend', e => {
            this.commitPolygon();
        });
    },

    /**
     * Commit Polygon.
     */
    async commitPolygon() {
        self.map.closePopup();

        if (
            self.drawnShapePoly &&
            self.drawnShapePoly._parts &&
            self.drawnShapePoly._parts[0] &&
            self.drawnShapePoly._parts[0].length > 2
        ) {
            document.querySelector('.drawing-tutorial-box').style.display = 'none';
            self.drawnShapePoly.validShape = true;
            const newPoly = self
                .getPoly()
                .map(point =>
                    point
                        .map(coord => encodeURIComponent(coord))
                        .reverse()
                        .join(','),
                )
                .join(';');

            self.refresh();

            const pinsCounterUpdatedListener = e => {
                $wire.trackDrawEvent('results', parseInt(self.viewportLength));
                window.removeEventListener('pinsCounterUpdated', pinsCounterUpdatedListener);
            };
            window.addEventListener('pinsCounterUpdated', pinsCounterUpdatedListener);

            const encodedNewPoly = await $wire.getCompressMapAreaCoordinates(newPoly);
            setUrlParam('map-area', encodedNewPoly, true);
            $wire.dispatchTo('search.top-filters', 'updateFields', {
                params: {
                    'map-area': encodedNewPoly,
                },
            });
            $wire.dispatch('alertSavedStatusChanged', { url: window.location.href });
        } else {
            self.resetPolygon();
        }
    },

    /**
     * Reset Polygon.
     */
    resetPolygon() {
        if (self.drawnShapePoly) {
            self.map.removeLayer(self.drawnShapePoly);
            self.drawnShapePoly = null;
        }
        unsetUrlParam('map-area', true);
        self.map.addControl(self.newPolygonButton);
        self.map.removeControl(self.resetPolygonButton);

        self.refresh();
        $wire.dispatchTo('search.top-filters', 'clearFields', { params: ['map-area'] });
        $wire.dispatch('alertSavedStatusChanged', { url: window.location.href });
    },

    countPinsInViewport() {
        self.viewportLength = 0;
        let promoPinsInView = 0;
        let projectsPinsInView = 0;
        self.clusterLayerGroup.eachLayer(layer => {
            if (self.checkLayerInMapBounds(layer)) {
                self.viewportLength += layer.clusterSize ?? 1;
            }
        });

        self.promoLayerGroup.eachLayer(layer => {
            if (self.checkLayerInMapBounds(layer)) {
                promoPinsInView += 1;
            }
        });

        self.projectsLayerGroup.eachLayer(layer => {
            if (self.checkLayerInMapBounds(layer)) {
                projectsPinsInView += layer.listingsCount ?? 0;
            }
        });

        self.viewportLength = Math.max(self.viewportLength, promoPinsInView);
        self.viewportLength += projectsPinsInView;
        self.viewportLength = self.viewportLength.toLocaleString('en');

        window.dispatchEvent(new CustomEvent('pinsCounterUpdated', { detail: parseInt(self.viewportLength) }));
    },

    /* HELPERS START */
    closePopupNotInMapBounds() {
        if (self.hoverMarker) {
            if (
                self.hoverMarker.layer != 'listings' &&
                !self.viewportPins[self.hoverMarker?.options?.id] &&
                !self.viewportProjects[self.hoverMarker?.options?.id]
            ) {
                self.map.closePopup();
                self.hoverMarker = null;
            } else if (
                self.hoverMarker.layer == 'listings' &&
                !self.map.getBounds().contains(self.hoverMarker.getLatLng())
            ) {
                self.map.closePopup();
                self.hoverMarker = null;
            }
        } else {
            self.map.closePopup();
        }
    },

    checkLayerInMapBounds(layer) {
        return self.map.getBounds().contains(layer.getLatLng());
    },

    async goToListing(id) {
        if (self.isMobile) {
            return false;
        }

        if (!self.scrollCardIntoView(id) && !self.fetchingPage) {
            await self.debouncedRenderCards(1, id, true, () => self.scrollCardIntoView(id));
        }
    },

    scrollCardIntoView(id) {
        if (!document) {
            return false;
        }
        const card = document.querySelector(`[data-cy="listing-section"] [data-cy="listing-${id}"]`);
        if (!card) {
            return false;
        }
        card.scrollIntoView(self.scrollIntoViewOptions);

        return true;
    },

    getPoly() {
        if (self.drawnShapePoly && self.drawnShapePoly.validShape) {
            let latLngs = self.drawnShapePoly.getLatLngs();
            let points = latLngs[0].map(point => [point.lng, point.lat]);
            return points;
        } else {
            return false;
        }
    },

    polylineOptions() {
        return {
            color: '#048BA8',
            opacity: 1,
            weight: 3,
            fillColor: '#048BA8',
            fillOpacity: 0.3,
            fill: true,
            noClip: true,
            interactive: false,
        };
    },

    scrollIntoViewOptions() {
        return {
            behavior: 'smooth',
        };
    },

    getZoomRules(layer) {
        const displayRules = {
            /* default zoom or below */
            0: {
                top_listing_s: 30,
                top_listing_energy: 0,
                top_listing: 0,
                listings_projects: 30,
            },

            /* default zoom + 1 */
            1: {
                top_listing_s: 20,
                top_listing_energy: 20,
                top_listing: 20,
                listings_projects: 30,
            },

            /* default zoom + 2 or above */
            2: {
                top_listing_s: 15,
                top_listing_energy: 15,
                top_listing: 15,
                listings_projects: 30,
            },

            /* unclustered zoom level */
            max: {
                top_listing_s: 0,
                top_listing_energy: 0,
                top_listing: 0,
                listings_projects: 999,
            },
        };

        let rulesByZoom =
            displayRules[
                Math.max(0, Math.min(self.map.getZoom() - $wire.defaultZoom, Object.keys(displayRules).length - 2))
            ];

        if (self.map.getZoom() >= self.disableClusteringAtZoom) {
            rulesByZoom = displayRules['max'];
        }

        return rulesByZoom[layer];
    },

    // Method to get bounds array
    getBoundsArray(decimals = -1) {
        const mapBounds = self.map.getBounds();
        const drawnShape = self.getPoly();
        if (drawnShape) {
            const boundsPolygon = [
                mapBounds.getWest(),
                mapBounds.getSouth(),
                mapBounds.getEast(),
                mapBounds.getNorth(),
            ];
            const shapePolygon = self.convertShapeToPolygon(drawnShape);

            return bboxClip(shapePolygon, boundsPolygon)?.geometry?.coordinates[0].map(v => {
                const point = [parseFloat(v[0]), parseFloat(v[1])];
                if (decimals === -1) {
                    return [point[0], point[1]];
                }

                return [round(point[0], decimals), round(point[1], decimals)];
            });
        } else if (mapBounds) {
            if (decimals === -1) {
                return [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()];
            }

            return [
                floor(mapBounds.getWest(), decimals),
                floor(mapBounds.getSouth(), decimals),
                ceil(mapBounds.getEast(), decimals),
                ceil(mapBounds.getNorth(), decimals),
            ];
        } else {
            return false;
        }
    },

    // Method to convert a shape to a GeoJSON polygon
    convertShapeToPolygon(shape) {
        // Check if the shape is valid
        if (!Array.isArray(shape) || shape.length === 0) {
            console.error('Invalid shape provided for conversion.');
            return null; // Return null if invalid
        }

        // Ensure the polygon is closed
        if (!shape[0].every((val, index) => val === shape[shape.length - 1][index])) {
            shape.push(shape[0]); // Close the polygon
        }

        return polygon([shape]);
    },

    // Method to convert bounds to a GeoJSON polygon
    convertBoundsToPolygon(bounds, decinals = 3) {
        return polygon([
            [
                [bounds._southWest.lng, bounds._northEast.lat], // top-left
                [bounds._northEast.lng, bounds._northEast.lat], // top-right
                [bounds._northEast.lng, bounds._southWest.lat], // bottom-right
                [bounds._southWest.lng, bounds._southWest.lat], // bottom-left
                [bounds._southWest.lng, bounds._northEast.lat], // close the polygon
            ],
        ]);
    },

    setZoomScale(zoomLevel) {
        var leafletContainer = document.querySelector('.leaflet-container');
        if (leafletContainer) {
            if (zoomLevel > 14) {
                leafletContainer.classList.remove('zoom_14');
            } else {
                leafletContainer.classList.add('zoom_14');
            }
        }
    },

    /*
     * Check if there is a pending save favourite action and if so, execute the action.
     */
    async addPendingFavourite(listingId, $dispatch, showLoginPage, loginRedirectUrl, fullUrl) {
        if (localStorage.getItem('storePendingFavorite')) {
            let selectedListingId = JSON.parse(localStorage.getItem('storePendingFavorite'));
            if (parseInt(selectedListingId) === listingId) {
                await window.toggleFavourite(listingId, false, $dispatch, showLoginPage, loginRedirectUrl, fullUrl);
                localStorage.removeItem('storePendingFavorite');
            }
        }
    },

    async toggleFavourite(listingId, isFavourite, $dispatch, showLoginPage, loginRedirectUrl, fullUrl) {
        if (showLoginPage == 'true') {
            $wire.setRedirectUrl(fullUrl);
            localStorage.setItem('storePendingFavorite', JSON.stringify(listingId));
            window.location.href = loginRedirectUrl;
        } else {
            if ($wire.authCheck) {
                let resp = null;
                if (isFavourite === false) {
                    resp = await $wire.addFavourite(listingId);
                } else if (isFavourite === true) {
                    resp = await $wire.removeFavourite(listingId);
                }
                return resp;
            } else {
                $dispatch('open-pop-up-auth', {
                    intended: $wire.fullUrl,
                    action: $wire.addToFavouriteClassRefference,
                    parameters: { listingId: listingId },
                });
            }
        }
        return false;
    },

    redirectToListing(path, target) {
        // Set custom cookie to track the source of the redirect.
        Cookies.set('accessedFrom', 'map');
        window.open(path, target);
    },

    get isMobile() {
        return window.innerWidth < 992;
    },

    isLowerThen(breakpoint) {
        if (breakpoint == 'xl') {
            breakpoint = 1200;
        }
        if (window.innerWidth < breakpoint) {
            return true;
        }

        return false;
    },

    async setCrsfToken() {
        self.crsfToken = await $wire.getCsrfToken();
    },

    toggleNewSearch() {
        window.dispatchEvent(new CustomEvent('toggleMobileFilters'));
        $wire.trackNewSearch();
    },
});
