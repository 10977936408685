import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';

export default (
    $wire,
    { activeMarkerIcon, inactiveMarkerIcon, mapDomContainer, searchBarContainer, projectCardContainer },
) => ({
    map: null,
    markers: [],
    mapDom: null,
    geocoder: null,
    mapLoader: null,
    searchBar: null,
    infoWindow: null,
    liveWireLoaded: false,
    projectName: null,
    projectLink: null,
    projectImage: null,
    projectPrice: null,
    showProjectLink: null,
    projectLocation: null,
    projectAvailability: null,
    projectUnits: null,
    projectAreaUnit: null,
    projects: $wire.entangle('projects').live,
    mapApiKey: $wire.entangle('mapApiKey').live,
    zoom: $wire.entangle('defaultMapZoom').live,
    center: $wire.entangle('defaultMapCenter').live,
    stats: $wire.entangle('searchResultsStats').live,
    selectedLocation: $wire.entangle('selectedLocation').live,
    defaultProjectImage: '/build/images/brk/default-card-thumbnail.jpg',

    init() {
        this.searchBar = document.getElementById(searchBarContainer);
        this.mapDom = document.getElementById(mapDomContainer);

        document.addEventListener('livewire:init', () => {
            this.liveWireLoaded = true;

            // Set map area height based on device viewable screen height
            this.mapDom.style.height =
                window.innerHeight - (this.searchBar.offsetHeight + this.searchBar.parentNode.offsetTop) + 'px';
        });

        window.addEventListener('locationChangedBrowserEvent', event => {
            [projects, address, stats] = event.detail;
            this.projects = projects;
            this.handleLocationChanged(address, stats);
        });

        this.mapLoader = new GoogleMapsLoader({ apiKey: this.mapApiKey });

        this.mapLoader.load().then(async () => {
            const { Geocoder } = await google.maps.importLibrary('geocoding');
            const { Map, InfoWindow } = await google.maps.importLibrary('maps');

            this.geocoder = new Geocoder();
            this.infoWindow = new InfoWindow({ content: '' });
            this.map = new Map(this.mapDom, {
                zoom: this.zoom,
                center: { lat: this.center.lat, lng: this.center.lng },
                mapTypeId: 'roadmap',
                gestureHandling: 'greedy',
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: false,
                styles: [
                    {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit.station.bus',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            });

            if (this.liveWireLoaded && this.selectedLocation && Object.keys(this.selectedLocation).length > 0) {
                this.handleLocationChanged(this.selectedLocation.label, this.stats);
            } else {
                this.displayProjectMarkers(google);
            }
        });
    },

    updateProjectCard(project) {
        this.projectName = project.name;
        this.projectPrice = project.price;
        this.projectUnits = project.units;
        this.projectLocation = project.location;
        this.projectAreaUnit = project.area_unit;
        this.showProjectLink = project.is_listed;
        this.projectLink = project.is_listed ? project.link : '#';
        this.projectImage = project.picture ? project.picture : this.defaultProjectImage;
    },

    async displayProjectMarkers(google) {
        const { LatLng } = await google.maps.importLibrary('core');
        const { Marker } = await google.maps.importLibrary('marker');

        for (let i = 0; i < this.projects.length; i++) {
            let project = this.projects[i];
            let marker = new Marker({
                position: new LatLng(project.latitude, project.longitude),
                map: this.map,
                icon: project.is_listed ? activeMarkerIcon : inactiveMarkerIcon,
            });

            this.markers.push(marker);
        }

        this.setMapOnAllMarkers(google, this.map);
    },

    // Display all markers on the map
    setMapOnAllMarkers(google, map) {
        for (let i = 0; i < this.markers.length; i++) {
            let marker = this.markers[i];
            marker.setMap(map);
            this.showProjectInfoWindowOnClick(google, marker, this.projects[i]);
        }
    },

    // Delete all markers from map
    deleteMarkers() {
        this.setMapOnAllMarkers(null);
        this.markers = [];
    },

    // Listen to clicks on project markers
    showProjectInfoWindowOnClick(google, marker, project) {
        google.maps.event.addListener(
            marker,
            'click',
            (marker => {
                return () => {
                    this.closeActiveProject();
                    this.updateProjectCard(project);
                    this.setContentOnInfoWindow();
                    this.openInfoWindow(marker);
                };
            })(marker),
        );
    },

    closeActiveProject() {
        this.infoWindow.close();
    },

    setContentOnInfoWindow() {
        let projectCardClone = document.getElementById(projectCardContainer).cloneNode(true);

        this.infoWindow.setContent(projectCardClone);
    },

    openInfoWindow(marker) {
        this.infoWindow.open(this.map, marker);
    },

    handleLocationChanged(address, stats) {
        this.deleteMarkers();

        this.geoCodeAddress(address);

        this.displayProjectMarkers();
    },

    geoCodeAddress(address) {
        this.geocoder.geocode(
            {
                address: address,
                componentRestrictions: {
                    country: 'KE',
                },
            },
            function (results, status) {
                if (status === 'OK') {
                    this.map.panTo(results[0].geometry.location);
                } else {
                    this.map.panTo(center);
                }
            },
        );
    },
});
